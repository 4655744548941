<template>
    <LiefengContent>
        <template v-slot:title>精准推送管理</template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
            <Button type="primary" @click="exportTableData" style="margin-left: 10px">
                <Icon type="ios-download-outline"></Icon>
                导出
            </Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="menuList"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="onSelect"
            ></LiefengTable>

            <!-- 新增修改弹窗 -->
            <LiefengModal class="map" :fullscreen="true" :title="JSON.stringify(currentFormData) !== '{}' ? '新增' : '新增'" :value="saveStatus" @input="saveStatusFn">
                <template v-slot:contentarea>
                    <div v-if="JSON.stringify(currentFormData) !== '{}'" style="height: 100%; overflow: hidden">
                        <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                            <FormItem label="推送内容" prop="title">
                                <Input v-model.trim="currentFormData.title" placeholder="限20字" maxlength="20"></Input>
                            </FormItem>
                            <FormItem label="提示说明" prop="remark">
                                <Input v-model.trim="currentFormData.remark" placeholder="限20字" maxlength="20"></Input>
                            </FormItem>
                            <FormItem label="来源机构" prop="orgName">
                                <Input v-model.trim="currentFormData.orgName" placeholder="限20字" maxlength="20"></Input>
                            </FormItem>
                            <FormItem label="编码或链接" prop="orgName">
                                <Input v-model.trim="currentFormData.qrCode" placeholder="限100字" maxlength="100"></Input>
                            </FormItem>
                            <FormItem label="渠道ID" prop="orgName">
                                <Input v-model.trim="currentFormData.batchId" placeholder="限100字" maxlength="100"></Input>
                            </FormItem>
                            <FormItem label="业务ID" prop="orgName">
                                <Input v-model.trim="currentFormData.businessId" placeholder="限100字" maxlength="100"></Input>
                            </FormItem>
                            <FormItem label="标签类型" prop="orgName">
                                <Select v-model="currentFormData.labelType" transfer placeholder="请选择标签类型" @on-change="changeTypeCode">
                                    <Option :value="item.labelType" v-for="(item, index) in labelTypeList" :key="index">{{ item.labelName }}</Option>
                                </Select>
                            </FormItem>
                            <!-- <FormItem label="定时推送时间" prop="orgName">
                                <DatePicker type="datetime" v-model="currentFormData.expectTime" placeholder="Select date and time" style="width: 200px" />
                            </FormItem> -->
                            <FormItem label="推送对象" prop="orgName">
                                <Tag @on-change="changeLable" :checked="item.checked" :checkable="true" v-for="(item, index) in labelList" color="primary" :key="index" :name="item.labelCode">
                                    {{ item.labelName }}
                                </Tag>
                                <!-- <Select v-model="currentFormData.labelCode" transfer placeholder="请选择标签画像" multiple filterable>
                                    <Option :value="item.labelCode" v-for="(item, index) in labelList" :key="index">{{ item.labelName }}</Option>
                                </Select> -->
                            </FormItem>
                        </Form>
                    </div>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="saveStatusFn(false)">取消</Button>
                    <Button type="primary" @click="submit">确定</Button>
                </template>
            </LiefengModal>
            <ModalView v-if="showModal" ref="ModalView" @closeModal="closeModal"></ModalView>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import ModalView from "./children/View"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        ModalView,
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    type: "selection",
                    width: 80,
                    align: "center",
                },
                {
                    title: "推送内容",
                    key: "title",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "来源",
                    key: "source",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "业务ID",
                    key: "businessId",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "推送类型",
                    key: "labelName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "编码或链接",
                    key: "qrCode",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "推送对象",
                    key: "userLabel",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "推送时间",
                    key: "gmtCreate",
                    minWidth: 100,
                    align: "center",
                },
                {
                    fixed: "right",
                    title: "操作",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.append({})
                                            this.currentFormData = {
                                                title: params.row.title,
                                                orgName: params.row.source,
                                                qrCode: '',
                                                labelCode: [],
                                                labelType: params.row.labelType,
                                                batchId: '',
                                                remark: params.row.remark,
                                                businessId:params.row.businessId
                                            }
                                            this.changeTypeCode(params.row.labelType)
                                            this.labelNameList = params.row.userLabel
                                        },
                                    },
                                },
                                "复制"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.showModal = true
                                            this.$nextTick(() => {
                                                // params.row.batchId = params.row.batchIds
                                                this.$refs.ModalView.open(params.row)
                                            })
                                        },
                                    },
                                },
                                "统计"
                            ),
                        ])
                    },
                },
            ],
            currentFormData: {
                title: "",
                remark: "",
                orgName: JSON.parse(sessionStorage.getItem("userDetail")).orgName,
                qrCode: "",
                labelCode: [],
                labelType: "",
                batchId: "",
                businessId:''
            },
            validateForm: {
                title: [{ required: true, message: "请输入推送内容", trigger: "blur" }],
            },

            saveStatus: false,
            labelList: [],
            labelTypeList: [],
            labelTypeCode: "",

            labelNameList: "",

            showModal: false,

            selectList: [],
        }
    },
    methods: {
        onSelect(selection) {
            if (selection && selection.length) {
                selection.map(item => {
                    this.selectList.push(item.batchId)
                })
            } else {
                this.selectList = []
            }
        },
        exportTableData() {
            if (this.selectList.length == 0) {
                this.$Message.warning({
                    content: "请先选择后在进行导出",
                    background: true,
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确认导出全部信息",
                onOk: () => {
                    this.$get(
                        "/gateway/api/v2/message/batch/exportBatchViews",
                        {
                            batchIds: this.selectList.join(','),
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            if (res.data) {
                                if (res.data.slice(0, 5) == "http") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }
                            let link = document.createElement("a")
                            link.download = "精准推送管理数据导出.xlsx"
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$Message.destroy()
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        closeModal() {
            console.log("触发关闭")

            this.showModal = false
        },
        changeTypeCode(val) {
            this.labelTypeCode = val
            if (val && val != "") this.getValidLabelConfigByLabelType()
        },
        getValidLabelConfigByLabelType() {
            this.$get("/gateway/api/lfuser/userLabel/getValidLabelConfigByLabelType", {
                labelType: this.labelTypeCode,
            }).then(res => {
                this.labelList = res.dataList.map(item => {
                    if (this.labelNameList && this.labelNameList != "") {
                        if (this.labelNameList.search(item.labelName) != -1) {
                            return {
                                ...item,
                                checked: true,
                            }
                        } else {
                            return {
                                ...item,
                                checked: false,
                            }
                        }
                    } else {
                        return {
                            ...item,
                            checked: false,
                        }
                    }
                })
            })
        },
        async getlabelType() {
            await this.$get("/gateway/api/lfuser/userLabel/getLabelType").then(res => {
                this.labelTypeList = res.dataList
            })
        },
        changeLable(val, name) {
            this.labelList.map(item => {
                if (item.labelCode == name) item.checked = val
            })
        },
        submit() {
            var list = []
            this.labelList.map(item => {
                if (item.checked) list.push(item.labelCode)
            })
            if (!list || !list.length) {
                this.$Message.warning({
                    content: "请选择推送对象",
                    background: true,
                })
                return
            }
            this.$Modal.confirm({
                title: "推送须知",
                content: "当前推送无法撤销，确定是否推送",
                okText: "立即推送",
                onOk: () => {
                    this.$refs.form.validate(status => {
                        if (status) {
                            if (this.labelTypeCode == "4") {
                                delete this.currentFormData.labelCode
                                this.$post("/gateway/api/lfuser/userLabel/sendMessageByStreet", {
                                    ...this.currentFormData,
                                    orgCode: list.join(","),
                                })
                                    .then(res => {
                                        if (res.code == 200) {
                                            this.$Message.success({
                                                background: true,
                                                content: "保存成功",
                                            })
                                            this.getSceneData(this.page)
                                            this.saveStatusFn(false)
                                        } else {
                                            this.$Message.error({
                                                background: true,
                                                content: res.desc,
                                            })
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err)
                                        this.$Message.error({
                                            background: true,
                                            content: "保存失败，请联系管理员处理",
                                        })
                                    })
                            } else {
                                this.$post("/gateway/api/lfuser/userLabel/sendMessage", {
                                    ...this.currentFormData,
                                    labelCode: list.join(","),
                                })
                                    .then(res => {
                                        if (res.code == 200) {
                                            this.$Message.success({
                                                background: true,
                                                content: "保存成功",
                                            })
                                            this.getSceneData(this.page)
                                            this.saveStatusFn(false)
                                        } else {
                                            this.$Message.error({
                                                background: true,
                                                content: res.desc,
                                            })
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err)
                                        this.$Message.error({
                                            background: true,
                                            content: "保存失败，请联系管理员处理",
                                        })
                                    })
                            }
                        }
                    })
                },
            })
        },
        saveStatusFn(status) {
            this.saveStatus = status
        },
        append(data) {
            this.currentFormData = {
                title: "",
                remark: "",
                orgName: JSON.parse(sessionStorage.getItem("userDetail")).orgName,
                qrCode: "",
                labelCode: [],
                labelType: "",
                batchId: "",
                businessId:this.$core.randomString()
            }
            this.labelNameList = ""
            this.labelList = []
            this.saveStatusFn(true)
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.getSceneData(obj.page)
        },
        async getSceneData(page) {
            this.loading = true
            await this.$get("/gateway/api/lfuser/userLabel/selectMessageBatchPage", {
                page,
                pageSize: this.pageSize,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList.map(item => {
                            let json = JSON.parse(item.paramJson)
                            if (item.paramJson && json.labelType && json.labelType != "") {
                                this.labelTypeList.map(items => {
                                    if (json.labelType == items.labelType) {
                                        item.labelName = items.labelName
                                    }
                                })
                            }

                            return {
                                batchIds: item.batchId,
                                ...item,
                                ...json,
                            }
                        })
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 点击了地图

        async getDictTye() {
            await this.$get("/gateway/api/lfuser/userLabel/getValidLabelConfig", {
                appCode: "",
            }).then(res => {
                if (res.code == 200) {
                    this.labelList = res.dataList.map(item => {
                        return {
                            ...item,
                            checked: false,
                        }
                    })
                }
            })
        },
    },
    async created() {
        await this.getlabelType()
        await this.getSceneData(1)
        // this.getDictTye()
    },
}
</script>

<style lang='less'>
.leftMenu {
    // position: fixed;
    // top: 60px;
    // left: 0px;
    width: calc(100% - 600px);
    // bottom: 0;
    background: #ffffff;
    // overflow: auto;
    float: left;
}
.rightEdit {
    float: left;
    // position: fixed;
    // top: 60px;
    height: 600px;
    width: 600px;
    // right: 0;
    bottom: 0;
    // overflow: auto;
    border-left: #ddd solid 1px;
}
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding: 10px 30px 10px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
